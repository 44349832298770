import clsx from 'clsx'
import dayjs, { Dayjs } from 'dayjs'
import { FC } from 'react'

import { ScheduleWorkout } from 'entities/ScheduleWorkout'

import { useResize } from 'shared/lib/useResize'
import { getDayClasses, getRange } from 'shared/lib/utils'
import { IClass } from 'shared/types'

import './scheduleWeek.scss'

interface IScheduleWeek {
	readonly currentStartRange: dayjs.Dayjs
	readonly rangeClasses: IClass[]
}
export const ScheduleWeek: FC<IScheduleWeek> = ({
	currentStartRange,
	rangeClasses,
}) => {
	const daysRange = getRange(currentStartRange)
	const { isMediumScreen } = useResize()
	const isToday = (day: Dayjs) => day.isSame(dayjs(), 'day')
	const renderWeekDay = (day: Dayjs) => {
		const dayClasses = getDayClasses(rangeClasses, day)
		return (
			<div key={day.toString()} className="week-class__wrapper">
				{isMediumScreen && (
					<p className={clsx('week-class__date', 'uppercase')}>
						{day.format('dd,  D MMM')}
					</p>
				)}
				<div className="week-class-card fade-in">
					{dayClasses?.length > 0
						? dayClasses.map((classData) => (
								<ScheduleWorkout key={classData.id} classData={classData} />
						  ))
						: isMediumScreen && (
								<p className="fade-in week-class-card__no-classes">
									Нет тренировок
								</p>
						  )}
				</div>
			</div>
		)
	}
	return (
		<div>
			{!isMediumScreen && (
				<div className="week-days">
					{daysRange.map((day) => (
						<div
							key={day.toString()}
							className={clsx('date', isToday(day) && 'today')}
						>
							<span className="capitalize">{dayjs(day).format('dd')},</span>
							<span>{dayjs(day).format('D MMM')}</span>
						</div>
					))}
				</div>
			)}
			<div className={'week-view'}>
				{daysRange.map((day) =>
					isMediumScreen ? (
						<div
							key={day.toString()}
							id={day.isSame(dayjs(), 'day') ? 'week-mode-today' : ''}
							className="week-mobile-mode fade-in"
						>
							{renderWeekDay(day)}
						</div>
					) : (
						renderWeekDay(day)
					)
				)}
			</div>
		</div>
	)
}
