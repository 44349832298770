import dayjs, { Dayjs } from 'dayjs'

import { IClass } from 'shared/types'

export function getDayClasses(classes: IClass[], day: Dayjs) {
	return classes?.filter(
		({ date }) =>
			dayjs(date).format('YYYY-MM-DD') === dayjs(day).format('YYYY-MM-DD')
	)
}
