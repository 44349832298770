import clsx from 'clsx'
import React, { CSSProperties, FC, TouchEvent, useEffect } from 'react'

import { Arrows, Dots, Slide, useSliderStore } from 'entities/slider'

import DefImg from 'shared/assets/default.jpg'

import './slider.scss'

interface ISlider {
	readonly className?: string
	readonly autoPlay?: boolean
	readonly alt?: string
	readonly autoPlayTime?: number
	readonly arrows?: boolean
	readonly rectangleDots?: boolean
	readonly children?: React.ReactNode[]
}

export const Slider: FC<ISlider> = ({
	className,
	autoPlay = false,
	autoPlayTime = 5000,
	arrows = true,
	children,
	rectangleDots = false,
	alt,
}) => {
	const {
		setItems,
		touchPosition,
		setTouchPosition,
		changeSlide,
		items,
		slide,
	} = useSliderStore()
	useEffect(() => {
		const loadImages = async () => {
			const images = [DefImg, DefImg, DefImg] as string[]
			setItems(images)
		}
		if (children) {
			setItems(new Array(children.length).fill(''))
		} else {
			loadImages()
		}
	}, [setItems, children])

	const handleTouchStart = (e: TouchEvent<HTMLDivElement>) => {
		const touchDown = e.touches[0].clientX
		setTouchPosition(touchDown)
	}

	const handleTouchMove = (e: TouchEvent<HTMLDivElement>) => {
		if (touchPosition === null) {
			return
		}
		const currentPosition = e.touches[0].clientX
		const diff = touchPosition - currentPosition
		if (diff > 10) {
			changeSlide(1)
		} else if (diff < -10) {
			changeSlide(-1)
		}
		setTouchPosition(null)
	}

	useEffect(() => {
		if (!autoPlay) return
		const interval = setInterval(() => {
			changeSlide(1)
		}, autoPlayTime)

		return () => clearInterval(interval)
	}, [items.length, slide, autoPlay, autoPlayTime, changeSlide])

	return (
		<div
			className={clsx('slider', className)}
			onTouchStart={handleTouchStart}
			onTouchMove={handleTouchMove}
		>
			{arrows && <Arrows className="slider-container__arrows" />}
			<div
				className="slider__slides-list"
				style={{ '--translateX': `-${slide * 100}%` } as CSSProperties}
			>
				{children
					? children
					: items.map((slide, index) => (
							<Slide key={index} url={slide} alt={`Зал на ${alt}`} />
					  ))}
			</div>
			<Dots rectangle={rectangleDots} />
		</div>
	)
}
