import { clsx } from 'clsx'
import dayjs, { Dayjs } from 'dayjs'
import { FC, MouseEvent } from 'react'

import { useResize } from 'shared/lib/useResize'
import { getDayClasses } from 'shared/lib/utils'
import { IClass } from 'shared/types'

import MonthDayClass from '../dayClass/MonthDayClass'
import './monthDay.scss'

interface IMonthDay {
	readonly day: Dayjs
	readonly currentStartRange: Dayjs
	readonly toSelectedDay: (
		hasClasses: boolean
	) => ({ currentTarget }: MouseEvent<HTMLDivElement>) => void
	readonly rangeClasses: IClass[]
	readonly selectedDay: Dayjs
}

const MonthDay: FC<IMonthDay> = ({
	day,
	currentStartRange,
	toSelectedDay,
	rangeClasses,
	selectedDay,
}) => {
	const { isMobileScreen } = useResize()
	const isSelectedDay = day.isSame(dayjs(selectedDay), 'date')
	const dayClasses = getDayClasses(rangeClasses, day)
	const hasClasses = dayClasses?.length > 0
	const isPastDate = day.isBefore(dayjs(), 'day')
	const isCurrentMonth = day.isSame(dayjs(currentStartRange), 'month')
	const isToday = day.isSame(dayjs(), 'day')
	return (
		<div
			className={clsx(
				'month-day',
				hasClasses && 'has-classes',
				isToday && isCurrentMonth && 'month-today',
				isMobileScreen && 'mobile',
				isSelectedDay && 'selected',
				isPastDate && 'propagation-disabled'
			)}
			data-date={day.format('YYYY-MM-DD')}
			onClick={toSelectedDay(hasClasses)}
		>
			{isCurrentMonth && (
				<div className="date">
					<span className={clsx({ today: isToday })}>{day.format('D')}</span>
				</div>
			)}
			{dayClasses?.length > 0 && (
				<div className={clsx('classes')}>
					{dayClasses?.map((classData, idx) => (
						<MonthDayClass
							key={classData.id}
							classData={classData}
							idx={idx}
							classesCount={dayClasses?.length}
						/>
					))}
				</div>
			)}
			{isPastDate && <div className="past-date fade-in"></div>}
		</div>
	)
}

export default MonthDay
