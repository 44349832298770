import { IPlayer } from 'shared/types'

export const sortPlayers = (playerId: number, players: IPlayer[]) => {
	const result = [] as IPlayer[]
	for (let i = players.length - 1, j = 0; i >= 0; i--, j++) {
		if (players[i].id === playerId) {
			const temp = result[0]
			if (!temp) {
				result[0] = players[i]
			} else {
				result[0] = players[i]
				result[j] = temp
			}
		} else {
			result[j] = players[i]
		}
	}
	return result
}
