import { CiViewList } from 'react-icons/ci'

import { getClassTime } from 'shared/lib/getClassTime'
import { IClass } from 'shared/types'

import { useResize } from '../../../../../shared/lib/useResize/useResize'
import './monthDayClass.scss'

export default function MonthDayClass({
	classData,
	idx,
	classesCount,
}: {
	classData: IClass
	idx: number
	classesCount: number
}) {
	const { level, date, duration } = classData
	const maxClasses = 2
	const timeRange = getClassTime(date, duration)
	const limit = classesCount > maxClasses && idx + 1 >= maxClasses
	const isEnded = timeRange === 'Тренировка завершена'
	const { isMobileScreen, width } = useResize()
	return (
		<>
			{!isMobileScreen ? (
				<>
					{limit && idx + 1 === maxClasses && !isEnded && (
						<div className="month-day-class ">
							{width > 1650 && <CiViewList />}
							<span>+ еще {classesCount - maxClasses + 1}</span>
						</div>
					)}
					{(!limit || (isEnded && idx + 1 === maxClasses)) && (
						<div
							style={{ borderColor: level.color }}
							className="month-day-class"
						>
							{width > 1650 && <TimeIcon />}
							<span>{timeRange}</span>
						</div>
					)}
				</>
			) : (
				idx < maxClasses && (
					<svg
						width="12"
						height="13"
						viewBox="0 0 12 13"
						fill="none"
						xmlns="http://www.w3.org/2000/svg"
					>
						<circle cx="6" cy="6.59961" r="6" fill={level.color} />
					</svg>
				)
			)}
		</>
	)
}

function TimeIcon() {
	return (
		<div className="month-day-class__time_icon">
			<svg fill="none" xmlns="http://www.w3.org/2000/svg">
				<path
					d="M7.93162 0.5C3.78979 0.5 0.431641 3.85816 0.431641 8C0.431641 12.1418 3.78979 15.5 7.93162 15.5C12.0735 15.5 15.4315 12.1417 15.4315 8C15.4316 3.85816 12.0735 0.5 7.93162 0.5ZM8.40041 14.5387V13.625H7.46287V14.5387C4.21919 14.3081 1.62369 11.7125 1.39298 8.46885H2.30662V7.53131H1.39298C1.62369 4.28753 4.21919 1.69203 7.46287 1.46131V2.37495H8.40041V1.46134C11.6441 1.69203 14.2396 4.28756 14.4702 7.53134H13.5567V8.46888H14.4702C14.2395 11.7125 11.6441 14.3081 8.40041 14.5387ZM11.3822 6.00779C11.4463 6.11954 11.4079 6.26333 11.2962 6.32831L8.82026 7.75738C8.84162 7.83705 8.86916 7.914 8.86916 8C8.86916 8.51819 8.44932 8.93745 7.93166 8.93745C7.41384 8.93745 6.99412 8.51819 6.99412 8L4.44991 6.53148C4.22556 6.40154 4.14871 6.11494 4.27821 5.89065C4.40725 5.66544 4.69428 5.58859 4.9186 5.71862L7.47753 7.19626C7.61389 7.11937 7.76314 7.06258 7.93166 7.06258C8.19353 7.06258 8.42913 7.1706 8.59953 7.34459L11.0618 5.92178C11.1717 5.85674 11.3154 5.8952 11.3822 6.00779Z"
					fill="white"
				/>
			</svg>
		</div>
	)
}
