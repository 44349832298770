import { FC } from 'react'

import { ProfileCard } from 'entities/profileCard'

import { IUserData } from 'shared/api/account'
import { MailIcon, PhoneIcon, TelegramCircleIcon } from 'shared/icons'
import { formatDate, formatPhone } from 'shared/lib/utils'
import { useSessionStore } from 'shared/model'
import { IconTitle } from 'shared/ui/iconTitle'

import './profileMainInfo.scss'

interface IUserInfo {
	readonly data: IUserData
}

export const ProfileMainInfo: FC<IUserInfo> = ({ data }) => {
	const aboutData = [
		{ title: 'Логин', value: data.username ?? '-' },
		{ title: 'Рост', value: data.height ? `${data.height} см` : '-' },
		{
			title: 'День рождения',
			value: data.birthday ? formatDate(data.birthday) : '-',
		},
	]
	const defaultAvatar = useSessionStore((state) => state.defaultAvatar)
	return (
		<article className="user-info">
			<ProfileCard>
				<div className="user-info__main">
					<img
						className="user-info__main__avatar"
						src={data.avatar ?? defaultAvatar}
						alt="Аватар игрока"
					/>
					<div>
						<p className="user-info__main__name">
							{data.last_name}
							<br />
							{data.first_name} {data.patronymic}
						</p>
						<div className="user-info__main__contacts">
							<IconTitle
								className="user-info__main__contacts__item"
								Icon={PhoneIcon}
								title={data.phone ? formatPhone(data.phone) : '-'}
							/>
							<IconTitle
								className="user-info__main__contacts__item"
								Icon={TelegramCircleIcon}
								title={data.telegram ? data.telegram : '-'}
							/>
							<IconTitle
								className="user-info__main__contacts__item"
								Icon={MailIcon}
								title={data.email ? data.email : '-'}
							/>
						</div>
					</div>
				</div>
				<div className="user-info__about">
					<h3 className="user-info__about__title">Об игроке</h3>
					<ul className="user-info__about__list">
						{aboutData.map(({ title, value }) => (
							<li key={title} className="user-info__about__list__item">
								<span>{title}</span>
								<span>{value}</span>
							</li>
						))}
						<li className="user-info__about__list__item__about">
							<p>О себе</p>
							<p className="user-info__about__list__item__about__text">
								{data.about ? data.about : 'Информация отсутствует'}
							</p>
						</li>
					</ul>
				</div>
			</ProfileCard>
		</article>
	)
}
