import { create } from 'zustand'

import { IProduct } from 'shared/api/product/types'
import { ISubscribe } from 'shared/types'

interface IJoinClassStore {
	userSubscribes: ISubscribe[] | null
	setUserSubscribes: (subscribes: ISubscribe[] | null) => void
	subscribesForClass: IProduct[] | null
	setSubscribesForClass: (subscribes: IProduct[] | null) => void
	errorMessage: string | null
	setErrorMessage: (message: string | null) => void
	isChooseRoleStep: boolean
	setIsChooseRoleStep: (step: boolean) => void
	reset: () => void
}

export const useJoinClassStore = create<IJoinClassStore>()((set) => ({
	userSubscribes: null,
	setUserSubscribes: (subscribes: ISubscribe[] | null) =>
		set({ userSubscribes: subscribes }),
	subscribesForClass: null,
	setSubscribesForClass: (subscribes: IProduct[] | null) =>
		set({ subscribesForClass: subscribes }),
	errorMessage: null,
	setErrorMessage: (message: string | null) => set({ errorMessage: message }),
	isChooseRoleStep: false,
	setIsChooseRoleStep: (value) => set({ isChooseRoleStep: value }),
	reset: () =>
		set({
			userSubscribes: null,
			subscribesForClass: null,
			errorMessage: null,
			isChooseRoleStep: false,
		}),
}))
