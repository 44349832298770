import dayjs from 'dayjs'

export function getMonthMatrix(date = dayjs()) {
	const year = date.year()
	const month = date.month()
	const firstDayOfTheMonth = dayjs(new Date(year, month, 0)).day()
	let currentDayCount = 0 - firstDayOfTheMonth
	const rowCount = firstDayOfTheMonth === 6 ? 6 : 5
	const daysMatrix = new Array(rowCount).fill([]).map(() => {
		return new Array(7).fill(0).map(() => {
			currentDayCount++
			return dayjs(new Date(year, month, currentDayCount))
		})
	})
	return daysMatrix
}
