import clsx from 'clsx'
import dayjs from 'dayjs'
import { FC, useEffect, useState } from 'react'

import {
	JoinClassInfo,
	useJoinClassMutate,
	useJoinClassStore,
	useLeaveClassMutate,
	usePreJoinClassMutate,
} from 'entities/joinClass'

import { isPlayerJoined, sortPlayers } from 'shared/lib/utils'
import { useSessionStore } from 'shared/model'
import { IPlayer, IRoles } from 'shared/types'
import { Button } from 'shared/ui/button'
import { ProgressBar } from 'shared/ui/progressBar'

import './workoutSignUp.scss'

interface IWorkoutSignUp {
	readonly classId: number
	readonly date: string
	readonly rolesAvailable: IRoles
	readonly players: IPlayer[]
	readonly limit: number
	readonly free_remove_to_class: number
}

export const WorkoutSignUp: FC<IWorkoutSignUp> = ({
	classId,
	players,
	date,
	rolesAvailable,
	limit,
	free_remove_to_class,
}) => {
	const session = useSessionStore((state) => state.session)
	const [joined, setJoined] = useState(
		(session && isPlayerJoined(players, session.id)) ?? false
	)
	const crowded = players.length >= limit
	const {
		reset: resetStore,
		subscribesForClass,
		userSubscribes,
		errorMessage,
		isChooseRoleStep,
	} = useJoinClassStore(
		({
			reset,
			subscribesForClass,
			userSubscribes,
			errorMessage,
			isChooseRoleStep,
		}) => ({
			reset,
			subscribesForClass,
			userSubscribes,
			errorMessage,
			isChooseRoleStep,
		})
	)
	const defaultPlayersSquad = session
		? sortPlayers(session.id, players)
		: players
	const [playersSquad, setPlayersSquad] = useState(defaultPlayersSquad)
	const [playersCount, setPlayersCount] = useState(players.length)

	const { mutate: joinClassMutate, isPending: isJoinClassLoading } =
		useJoinClassMutate(
			classId,
			setPlayersCount,
			setJoined,
			undefined,
			setPlayersSquad
		)
	const { mutate: leaveClassMutate, isPending: isLeaveClassLoading } =
		useLeaveClassMutate(
			classId,
			setPlayersCount,
			setJoined,
			undefined,
			setPlayersSquad
		)
	const { mutate: preJoinClassMutate, isPending: isPreJoinClassLoading } =
		usePreJoinClassMutate(classId, date, undefined, joinClassMutate)

	useEffect(() => {
		if (session) {
			setPlayersSquad(sortPlayers(session.id, players))
			if (isPlayerJoined(players, session.id)) {
				setJoined(true)
			}
		} else {
			setJoined(false)
		}
		return () => {
			resetStore()
		}
	}, [session, players, resetStore])

	const handleJoin = () => {
		preJoinClassMutate({ classId, is_role_mode: !!rolesAvailable })
	}
	const freeRemoveDeadline = dayjs(date).subtract(free_remove_to_class, 'hour')
	const isEndedClass = dayjs(date).diff(dayjs()) < 0
	const isFreeRemoveAvailable = dayjs().isBefore(freeRemoveDeadline)
	const isJoinProcess =
		userSubscribes || errorMessage || subscribesForClass || isChooseRoleStep

	const isStandByJoinProcess =
		!userSubscribes && !errorMessage && !subscribesForClass
	const renderSquad = () => {
		return session && playersSquad.length > 0 ? (
			<div className="workout-sign-up__squad fade-in">
				<ol type="1" className="workout-sign-up__squad__list">
					{playersSquad.map((player, idx) => (
						<li
							className={'workout-sign-up__squad__list__item'}
							key={player.id}
						>
							<span
								className={clsx(player.id === session?.id && 'green-highlight')}
							>{`${idx + 1}. ${player.role ? player.role + ':' : ''} ${
								player.first_name
							} ${player.last_name}`}</span>
						</li>
					))}
					{playersSquad.length < limit - 1 && (
						<>
							<li>...</li>
							<li className={'workout-sign-up__squad__list__item'}>{limit}.</li>
						</>
					)}
				</ol>
			</div>
		) : isEndedClass ? (
			<div>Запись на тренировку завершена</div>
		) : (
			<div>
				<p>
					Занято {playersCount} из {limit}
				</p>
				<ProgressBar currentValue={playersCount} maxValue={limit} />
			</div>
		)
	}
	return (
		<article className={clsx('workout-sign-up')}>
			<div className="workout-sign-up__header">
				<div>
					<p className="workout-sign-up__header__title">Состав</p>
				</div>
			</div>
			<div
				onClick={(e) => {
					if (e.currentTarget.ariaLabel !== 'Закрыть окно') {
						e.stopPropagation()
					}
				}}
				className="workout-sign-up__wrapper"
			>
				{isJoinProcess ? (
					<JoinClassInfo
						classId={classId}
						date={date}
						rolesAvailable={rolesAvailable}
						handleJoinClass={joinClassMutate}
						isJoinClassLoading={isJoinClassLoading}
					/>
				) : (
					renderSquad()
				)}
			</div>
			{isStandByJoinProcess && (
				<div className="workout-sign-up__footer">
					{joined ? (
						<Button
							isLoading={isLeaveClassLoading}
							onClick={() => leaveClassMutate(classId)}
							className="workout-sign-up__footer__button"
							theme="red"
						>
							Отменить запись
						</Button>
					) : (
						!isJoinProcess && (
							<Button
								isLoading={isPreJoinClassLoading || isJoinClassLoading}
								className="workout-sign-up__footer__button"
								disabled={crowded}
								onClick={handleJoin}
							>
								{crowded ? 'Запись закрыта' : 'Записаться'}
							</Button>
						)
					)}
					<span className="workout-sign-up__footer__free-leave-tip">
						<p>
							{`Бесплатная отмена записи
						${
							isFreeRemoveAvailable
								? `до ${freeRemoveDeadline.format('HH:mm, D MMM')}`
								: 'уже недоступна'
						}
							`}
						</p>
					</span>
				</div>
			)}
		</article>
	)
}
