import dayjs from 'dayjs'

export function getStartEndRange(
	currentDate: dayjs.Dayjs = dayjs(),
	type = 'week'
) {
	let start
	let end
	const date = dayjs(currentDate)
	const params = new URLSearchParams()
	switch (type) {
		case 'month': {
			const startRange = date.startOf('month')
			const daysInMonth = date.daysInMonth()
			const endRange = startRange.add(daysInMonth, 'day')
			start = startRange.format('YYYY-MM-DD')
			end = endRange.format('YYYY-MM-DD')
			break
		}
		case 'week': {
			start = date.isoWeekday(1).format('YYYY-MM-DD')
			end = date.isoWeekday(1).add(7, 'day').format('YYYY-MM-DD')
			break
		}
		default: {
			start = date.format('YYYY-MM-DD')
			end = date.add(1, 'day').format('YYYY-MM-DD')
			break
		}
	}
	params.set('start_date', start)
	params.set('end_date', end)
	return { strParam: params.toString(), start_date: start, end_date: end }
}
