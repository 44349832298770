import ArrowLeft from 'components/IconComponents/ArrowLeft'
import ArrowRight from 'components/IconComponents/ArrowRight'
import dayjs from 'dayjs'

import { useRange } from 'shared/lib/utils'
import { useScheduleStore } from 'shared/model'

import './rangeSwitcher.scss'

export default function CurrentRangeSwitcher() {
	const { scheduleView, setCurrentStartRange } = useScheduleStore(
		({ scheduleView, setCurrentStartRange, setRequestParams }) => ({
			scheduleView,
			setCurrentStartRange,
			setRequestParams,
		})
	)
	const { currentStartRange, ranges } = useRange()
	const { type, range } = ranges[scheduleView]

	const prevRange = currentStartRange.subtract(range, type)
	const nextRange = currentStartRange.add(range, type)
	const handleDownRange = () => {
		setCurrentStartRange(prevRange)
	}

	const handleUpRange = () => {
		setCurrentStartRange(nextRange)
	}

	return (
		<div className={'switcher'}>
			<button onClick={handleDownRange} className="arrow-btn">
				<ArrowLeft />
			</button>
			<button
				className="today-btn"
				onClick={() => setCurrentStartRange(dayjs())}
			>
				Сегодня
			</button>
			<button onClick={handleUpRange} className="arrow-btn">
				<ArrowRight />
			</button>
		</div>
	)
}
