import {
	PasswordKeyIcon,
	RepeatIcon,
	SettingsIcon,
	SubscribeIcon,
	UserPerson,
} from 'shared/icons'

export const PROFILE_LINKS = [
	{
		title: 'Мой профиль',
		path: '/profile/about',
		Icon: UserPerson,
	},
	{
		title: 'Мои абонементы',
		path: '/profile/subscribes',
		Icon: SubscribeIcon,
	},
	{
		title: 'Настройки профиля',
		path: '/profile/edit',
		Icon: SettingsIcon,
	},
	{
		title: 'Изменение пароля',
		path: '/profile/change-password',
		Icon: PasswordKeyIcon,
	},
	{
		title: 'История тренировок',
		path: '/profile/history',
		Icon: RepeatIcon,
	},
]
