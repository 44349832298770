import { FC } from 'react'

import { ReactComponent as GreenArrowSvg } from 'shared/assets/icons/greenArrow.svg'

interface IGreenArrow {
	readonly className?: string
}

export const GreenArrow: FC<IGreenArrow> = ({ className }) => {
	return <GreenArrowSvg className={className} />
}
