import clsx from 'clsx'
import { FC } from 'react'

import { useSliderStore } from 'entities/slider/model/slider-store'

import { ArrowBlackBg } from 'shared/icons'

import './arrows.scss'

interface IArrows {
	readonly className?: string
}

export const Arrows: FC<IArrows> = ({ className }) => {
	const changeSlide = useSliderStore((state) => state.changeSlide)
	return (
		<div className={clsx('slider-arrows', className)}>
			<button className="slider-arrows__left" onClick={() => changeSlide(-1)}>
				<ArrowBlackBg />
			</button>
			<button className="slider-arrows__right" onClick={() => changeSlide(1)}>
				<ArrowBlackBg />
			</button>
		</div>
	)
}
