import dayjs from 'dayjs'
import isoWeek from 'dayjs/plugin/isoWeek'
import { FC, MouseEvent, useEffect } from 'react'
import { Link } from 'react-router-dom'

import { useResize } from 'shared/lib/useResize'
import { getDayClasses, getMonthMatrix } from 'shared/lib/utils'
import { IClass } from 'shared/types'
import { Interval } from 'shared/ui/interval'
import { LevelInfo } from 'shared/ui/level'

import { useScheduleStore } from '../../../../shared/model/schedule-store'
import './month.scss'
import MonthDay from './monthDay/MonthDay'
import { MonthWeekDays } from './monthWeekDays/monthWeekDays'

dayjs.extend(isoWeek)
dayjs.locale('ru')
interface IMonth {
	readonly currentStartRange: dayjs.Dayjs
	readonly rangeClasses: IClass[]
}
const Month: FC<IMonth> = ({ currentStartRange, rangeClasses }) => {
	const { selectedDay, setSelectedDay, setCurrentStartRange } =
		useScheduleStore(
			({ selectedDay, setSelectedDay, setCurrentStartRange }) => ({
				selectedDay,
				setSelectedDay,
				setCurrentStartRange,
			})
		)
	const month = getMonthMatrix(currentStartRange)
	useEffect(() => {
		const firstDayWithClasses = rangeClasses.find((item) =>
			dayjs().isBefore(item.date, 'day')
		)
			? dayjs(rangeClasses[0].date)
			: currentStartRange.isSame(dayjs(), 'month')
			? dayjs()
			: dayjs(currentStartRange.date(1))
		setSelectedDay(firstDayWithClasses)
	}, [rangeClasses])
	const selected = dayjs(selectedDay)
	const selectedDayClasses = getDayClasses(rangeClasses, selected) ?? []
	const { isTabletScreen } = useResize()
	const toSelectedDay =
		(hasClasses: boolean) =>
		({ currentTarget }: MouseEvent<HTMLDivElement>) => {
			if (!hasClasses) {
				return
			}
			const date = currentTarget.dataset.date
			setCurrentStartRange(dayjs(date))
			setSelectedDay(dayjs(date))
			if (isTabletScreen) {
				const element = document.getElementById('small-class-container')
				if (element) {
					element.scrollIntoView({ block: 'end', behavior: 'smooth' })
				}
			} else {
				window.scroll({
					behavior: 'smooth',
					top: 100,
					left: 0,
				})
			}
		}

	return (
		<div className="month-schedule">
			<div className="month-schedule__wrapper">
				<MonthWeekDays />
				<div className="month-view">
					{month.map((row, i) => (
						<div className="month-week" key={i}>
							{row.map((day) => {
								return (
									<MonthDay
										key={day.format('YYYY-MM-DD')}
										day={day}
										currentStartRange={currentStartRange}
										toSelectedDay={toSelectedDay}
										rangeClasses={rangeClasses}
										selectedDay={selected}
									/>
								)
							})}
						</div>
					))}
				</div>
			</div>
			<div id="small-class-container" className="selected-day-classes">
				<div className="selected-day-classes__date">
					<span>{dayjs(selectedDay).format('dddd, D MMM')}</span>
				</div>
				{selectedDayClasses.map(
					({ date, duration, id, level, is_changed_level }) => {
						return (
							<Link
								to={`/workout/${id}`}
								key={id + date}
								className="selected-day-classes__class"
							>
								<div className="selected-day-classes__class__about">
									<LevelInfo
										className="selected-day-classes__class__about__level"
										levelTitle={level.title}
										levelColor={level.color}
										is_changed_level={is_changed_level}
									/>
									<Interval start={date} duration={duration} />
								</div>
								<svg
									width="11"
									height="18"
									viewBox="0 0 11 18"
									fill="none"
									xmlns="http://www.w3.org/2000/svg"
								>
									<path
										d="M1.25 0.75L9.01875 8.51875C9.14472 8.64726 9.21528 8.82004 9.21528 9C9.21528 9.17996 9.14472 9.35274 9.01875 9.48125L1.25 17.25"
										stroke="white"
										strokeWidth="2"
									/>
								</svg>
							</Link>
						)
					}
				)}
			</div>
		</div>
	)
}

export default Month
