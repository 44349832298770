import { FC } from 'react'

import { ProfileCard } from 'entities/profileCard'

import { IUserData } from 'shared/api/account'
import { pluralAge } from 'shared/lib/utils'
import { LevelInfo } from 'shared/ui/level'

import './profileGameData.scss'

interface IProfileGameData {
	readonly data: IUserData
}

export const ProfileGameData: FC<IProfileGameData> = ({ data }) => {
	return (
		<article className="game-data">
			<ProfileCard>
				<h3 className="game-data__title">Игровые данные</h3>
				<ul className="game-data__content">
					<li className="game-data__content__item">
						<span>Уровень</span>
						<LevelInfo
							levelTitle={data.level_id.title}
							levelColor={'aqua'}
							className="game-data__content__item__level"
							is_changed_level={false}
						/>
					</li>
					<li className="game-data__content__item">
						<span>Амплуа</span>
						<span>
							{data.role ? data.role.map(({ name }) => name).join(', ') : '-'}
						</span>
					</li>
					<li className="game-data__content__item">
						<span>Команда</span>
						<span>
							{data?.team?.length
								? data.team.map(({ title }) => title).join(', ')
								: '-'}
						</span>
					</li>
					<li className="game-data__content__item">
						<span>Игровой опыт</span>
						<span>{data.experience ? pluralAge(data.experience) : '-'}</span>
					</li>
				</ul>
			</ProfileCard>
		</article>
	)
}
