import dayjs, { ManipulateType } from 'dayjs'
import isoWeek from 'dayjs/plugin/isoWeek'

import { useScheduleStore } from 'shared/model'

dayjs.extend(isoWeek)

export function useRange() {
	const currentStartRange = useScheduleStore((state) =>
		dayjs(state.currentStartRange)
	)
	const rangeString = currentStartRange.format('MMMM YYYY')

	return {
		currentStartRange,
		ranges: {
			month: { type: 'month' as ManipulateType, range: 1, rangeString },
			week: { type: 'day' as ManipulateType, range: 7, rangeString },
		},
	}
}
