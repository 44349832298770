import clsx from 'clsx'
import { FC } from 'react'

import './progressBar.scss'

interface IProgressBar {
	readonly maxValue: number
	readonly currentValue: number
}

export const ProgressBar: FC<IProgressBar> = ({ currentValue, maxValue }) => {
	return (
		<div className="progress-bar">
			{new Array(maxValue).fill(null).map((_, idx) => (
				<div
					className={clsx('progress-bar__item', currentValue > idx && 'active')}
					key={idx}
				/>
			))}
		</div>
	)
}
