import clsx from 'clsx'
import { FC } from 'react'
import { PuffLoader } from 'react-spinners'

interface IPuffSpinner {
	readonly isInsideContainer?: boolean
}

export const PuffSpinner: FC<IPuffSpinner> = ({ isInsideContainer }) => {
	return (
		<div
			className={clsx(
				isInsideContainer ? 'absolute-center-container' : 'absolute-center'
			)}
		>
			<PuffLoader size={120} color="var(--green)" />
		</div>
	)
}
