import man1 from 'shared/assets/man-1.webp'
import man2 from 'shared/assets/man-2.webp'
import man3 from 'shared/assets/man-3.webp'
import woman1 from 'shared/assets/woman-1.webp'
import woman2 from 'shared/assets/woman-2.webp'
import woman3 from 'shared/assets/woman-3.webp'

export const getRandomAvatar = (gender: 'm' | 'f' | null) => {
	const man = [man1, man2, man3]
	const woman = [woman1, woman2, woman3]
	const randomIndex = Math.floor(Math.random() * man.length)
	return gender === 'f' ? woman[randomIndex] : man[randomIndex]
}
