import clsx from 'clsx'
import { FC } from 'react'

import { useSliderStore } from 'entities/slider/model/slider-store'

import { Dot } from './dot'
import './dots.scss'

interface IDots {
	readonly rectangle?: boolean
}

export const Dots: FC<IDots> = ({ rectangle = false }) => {
	const slides = useSliderStore((state) => state.items)
	return (
		<div className={clsx('slider-dots', rectangle && 'rectangle')}>
			{slides.map((_, index) => (
				<Dot slideNumber={index} key={`dot-${index}`} />
			))}
		</div>
	)
}
