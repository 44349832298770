import clsx from 'clsx'
import dayjs from 'dayjs'
import 'dayjs/locale/ru'
import { FC, useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'

import {
	JoinClassInfo,
	useJoinClassMutate,
	useJoinClassStore,
	useLeaveClassMutate,
	usePreJoinClassMutate,
} from 'entities/joinClass'

import { useResize } from 'shared/lib/useResize'
import { useSessionStore } from 'shared/model'
import { IClass } from 'shared/types'
import { IRoles } from 'shared/types/classTypes'
import { GymPhoto } from 'shared/ui'
import { Button } from 'shared/ui/button'
import { Interval } from 'shared/ui/interval'
import { LevelInfo } from 'shared/ui/level'
import { Metro } from 'shared/ui/metro'
import { Modal } from 'shared/ui/modal'
import { Trainer } from 'shared/ui/trainer'

import './ScheduleWorkout.scss'

dayjs.locale('ru')

interface IScheduleWorkout {
	classData: IClass
}
export const ScheduleWorkout: FC<IScheduleWorkout> = ({ classData }) => {
	const navigate = useNavigate()

	const { session } = useSessionStore(({ session }) => ({
		session,
	}))
	const {
		id: classId,
		date,
		free_remove_to_klass,
		limit,
		players,
		roles_available,
		is_role_mode,
		level,
		gym,
		duration,
		trainer,
		is_changed_date,
		is_changed_gym,
		is_changed_level,
		is_changed_trainer,
	} = classData
	const isPlayerAlreadyJoined = session ? players.includes(session.id) : false
	const [joined, setJoined] = useState(isPlayerAlreadyJoined) // Состояние записи пользователя
	const [isModalOpen, setIsModalOpen] = useState(false)
	/** Количество игроков занятия */
	const [playersCount, setPlayersCount] = useState(players.length)

	const resetStore = useJoinClassStore((state) => state.reset)
	const handleCloseModal = () => {
		setIsModalOpen(false)
		resetStore()
	}
	const handleOpenModal = () => {
		setIsModalOpen(true)
	}

	const {
		mutate: mutateJoinClass,
		isPending: isJoinClassLoading,
		isError: isJoinClassError,
	} = useJoinClassMutate(classId, setPlayersCount, setJoined, handleCloseModal)
	const { mutate: mutatePreJoinClass, isPending: isPreJoinClassLoading } =
		usePreJoinClassMutate(classId, date, handleOpenModal, mutateJoinClass)

	const { mutate: mutateLeaveClass, isPending: isLeaveClassLoading } =
		useLeaveClassMutate(classId, setPlayersCount, setJoined, setIsModalOpen)
	const { state } = useLocation()
	useEffect(() => {
		const delayed = sessionStorage.getItem('delayed_appointment')

		const isFromSuccess = state?.from === 'payment-success'
		if (delayed && isFromSuccess) {
			const delayed_data = JSON.parse(delayed) as {
				classId: number
				role: keyof IRoles
			}
			if (delayed_data.classId === classId) {
				const schedule = document.querySelector('.schedule')
				schedule?.scrollIntoView({ behavior: 'smooth' })
				mutatePreJoinClass({
					classId,
					is_role_mode,
					delayed_join: true,
					role: delayed_data.role,
				})
				sessionStorage.removeItem('delayed_appointment')
			}
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])
	useEffect(() => {
		if (session && players.includes(session.id)) {
			setJoined(true)
		} else {
			setJoined(false)
		}
	}, [players, session])

	const freeRemoveDeadline = dayjs(date).subtract(free_remove_to_klass, 'hour')
	const isEndedClass = dayjs(date).diff(dayjs()) < 0
	const crowded = playersCount >= limit

	const isFreeRemoveAvailable = dayjs().isBefore(freeRemoveDeadline)
	const { isMediumScreen } = useResize()
	return (
		<>
			<article
				className={clsx('schedule-class', isEndedClass && 'ended-class')}
			>
				<div className="schedule-class__content">
					<LevelInfo
						levelTitle={level.title}
						levelColor={level.color}
						is_changed_level={is_changed_level}
					/>
					<GymPhoto
						img={gym.image}
						className="schedule-class__content__gym-img"
					/>
					<Metro
						className={clsx(is_changed_gym && 'red-highlight')}
						title={gym.title}
					/>
					<Interval
						start={date}
						duration={duration}
						className={clsx(is_changed_date && 'red-highlight')}
					/>
					<Trainer
						first_name={trainer.first_name}
						last_name={trainer.last_name}
						shouldBreak={!isMediumScreen}
						className={clsx(
							'schedule-class__content_trainer',
							is_changed_trainer && 'red-highlight'
						)}
					/>
				</div>
				{!joined && (
					<div className="schedule-class__join-class">
						<Button
							disabled={crowded}
							className={'schedule-class__join-class__btn schedule-class__btn'}
							isLoading={
								(isJoinClassLoading && !isJoinClassError) ||
								(isPreJoinClassLoading && !isJoinClassError)
							}
							theme="yellow"
							onClick={(e) => {
								e.stopPropagation()
								mutatePreJoinClass({ classId, is_role_mode })
							}}
						>
							{!crowded ? 'Быстрая запись' : '-'}
						</Button>
						{crowded && <div className="crowded">Запись закрыта</div>}
					</div>
				)}

				{session && joined && (
					<Button
						className={'schedule-class__leave-class__btn schedule-class__btn'}
						theme="red"
						isLoading={isLeaveClassLoading}
						onClick={(e) => {
							e.stopPropagation()
							mutateLeaveClass(classId)
						}}
					>
						Отменить запись
					</Button>
				)}
				<Button
					className="fade-in schedule-class__btn"
					onClick={() => {
						navigate(`/workout/${classId}`)
					}}
				>
					Подробнее
				</Button>
				<span className="schedule-class__free-leave-tip">
					<p>Бесплатная отмена записи</p>
					<p>
						{isFreeRemoveAvailable
							? `до ${freeRemoveDeadline.format('HH:mm, D MMM')}`
							: 'уже недоступна'}
					</p>
				</span>
				{isEndedClass && <div className="ended"></div>}
			</article>
			{isModalOpen && (
				<Modal closeButton onClose={handleCloseModal}>
					<h1 className="schedule-class__modal-title">Запись на тренировку</h1>
					<JoinClassInfo
						handleJoinClass={mutateJoinClass}
						rolesAvailable={roles_available}
						date={date}
						isJoinClassLoading={isJoinClassLoading}
						classId={classId}
					/>
				</Modal>
			)}
		</>
	)
}
