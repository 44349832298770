import BlogPage from 'components/BlogPage/BlogPage'
import ErrorPage from 'components/ErrorPage/ErrorPage'
import ConfirmationEmailPage from 'components/RegistrationPage/Confirmation/ConfirmationEmailPage'
import ConfirmationPhonePage from 'components/RegistrationPage/Confirmation/СonfirmationPhonePage'
import PasswordEmailRecoveryPage from 'components/RegistrationPage/PasswodrRecoveryPage/PasswordEmailRecoveryPage'
import PasswordEmailRecoveryTwoPage from 'components/RegistrationPage/PasswodrRecoveryPage/PasswordEmailRecoveryTwoPage'
import RegistrationPage from 'components/RegistrationPage/RegistrationPage'
import StaticPage from 'components/StaticPage/StaticPage'
import { FC } from 'react'
import { RouterProvider, createBrowserRouter, redirect } from 'react-router-dom'

import { Layout } from 'app/layout'

import ConfirmTokenPage from 'pages/ConfirmTokenPage'
import GymPage from 'pages/GymPage/GymPage'
import MainPage from 'pages/MainPage/MainPage'
import PaymentFailedPage from 'pages/PaymentFailedPage'
import PaymentPage from 'pages/PaymentPage/PaymentPage'
import PaymentSuccessPage from 'pages/PaymentSuccessPage'
import SchedulePage from 'pages/SchedulePage/SchedulePage'
import TrainersPage from 'pages/TrainersPage/TrainersPage'
import TrainingPage from 'pages/TrainingPage/TrainingPage'
import { Profile } from 'pages/profile'
import { ProfileAbout } from 'pages/profile/about'
import { ChangePassword } from 'pages/profile/change-password'
import { ProfileHistory } from 'pages/profile/history'
import { ProfileSettings } from 'pages/profile/settings'
import { ProfileSubscribes } from 'pages/profile/subscribes'
import { Workout } from 'pages/workout'

import { getSession } from 'shared/lib/auth'
import { useSessionStore } from 'shared/model'

interface IAppRouter {}

export const AppRouter: FC<IAppRouter> = () => {
	const setIsPopupAccountOpen = useSessionStore(
		(state) => state.setIsPopupAccountOpen
	)
	const router = createBrowserRouter([
		{
			path: '/',
			element: <Layout />,
			errorElement: (
				<Layout>
					<ErrorPage />
				</Layout>
			),
			children: [
				{
					index: true,
					element: <MainPage />,
				},
				{
					path: 'blog',
					element: <BlogPage />,
				},
				{
					path: 'training',
					element: <TrainingPage />,
				},
				{
					path: 'gym',
					element: <GymPage />,
				},
				{
					path: 'trainers',
					element: <TrainersPage />,
				},
				{
					path: 'payment',
					element: <PaymentPage />,
				},
				{
					path: 'payment-success',
					element: <PaymentSuccessPage />,
				},
				{
					path: 'payment-failed',
					element: <PaymentFailedPage />,
				},
				{
					path: 'page/:slug',
					element: <StaticPage />,
				},
				{
					path: 'schedule',
					element: <SchedulePage />,
				},
				{
					path: 'registration',
					element: <RegistrationPage />,
					loader: () => {
						const session = getSession()
						if (session) {
							return redirect('/profile/')
						}
						return null
					},
				},
				{
					path: 'confirmation-email',
					element: <ConfirmationEmailPage />,
				},
				{
					path: 'confirmation-phone',
					element: <ConfirmationPhonePage />,
				},
				{
					path: 'confirm/:token',
					element: <ConfirmTokenPage />,
				},
				{
					path: 'recovery-password-email',
					element: <PasswordEmailRecoveryPage />,
				},
				{
					path: 'recovery-password-email/newpassword',
					element: <PasswordEmailRecoveryTwoPage />,
				},
				{
					path: 'profile',
					element: <Profile />,
					loader: () => {
						const session = getSession()
						if (!session) {
							setIsPopupAccountOpen(true)
							return redirect('/')
						}
						return null
					},
					children: [
						{
							path: 'about',
							element: <ProfileAbout />,
						},
						{
							path: 'subscribes',
							element: <ProfileSubscribes />,
						},
						{
							path: 'edit',
							element: <ProfileSettings />,
						},
						{
							path: 'change-password',
							element: <ChangePassword />,
						},
						{
							path: 'history',
							element: <ProfileHistory />,
						},
					],
				},
				{
					path: 'workout/:id',
					element: <Workout />,
				},
			],
		},
	])
	return <RouterProvider router={router} />
}
