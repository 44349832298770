import type { IClass, IClassById, IClassType, ISubscribe } from 'shared/types'
import { IRoles } from 'shared/types/classTypes'

import { api, tokenApi } from '../base'
import { IJoinClass, ILeaveClass } from './types'

export const getTypeClasses = () => {
	return api.get<IClassType[]>('type-training/')
}

export const getClasses = (params: string) => {
	return api.get<IClass[]>(`klass/?${params}`)
}
export const getClassById = (id: number) => {
	return api.get<IClassById>(`klass/${id}/`)
}

export const leaveClass = async (id: number) => {
	const response = await tokenApi.post<ILeaveClass>(
		`remove_as_training/${id}/`,
		{}
	)
	return response
}

export const preJoinClass = async (id: number) => {
	const response = await tokenApi.post<ISubscribe[]>(
		`make_as_training/${id}/`,
		{}
	)
	return response
}
export const joinClass = async (
	id: number,
	subscribeId: number,
	role?: keyof IRoles | null
) => {
	const response = await tokenApi.post<IJoinClass>(
		`make_as_training/${id}/${subscribeId}/`,
		role ? { role } : {}
	)
	return response
}
