import clsx from 'clsx'
import { ButtonHTMLAttributes, FC, useEffect } from 'react'
import { FadeLoader, SyncLoader } from 'react-spinners'

import './button.scss'

interface IButton extends ButtonHTMLAttributes<HTMLButtonElement> {
	readonly theme?: 'green' | 'transparent-green' | 'red' | 'yellow' | 'black'
	readonly isLoading?: boolean
	readonly bold?: boolean
	readonly rounded?: boolean
	readonly centered?: boolean
	readonly size?: 'small' | 'medium' | 'large'
}

export const Button: FC<IButton> = (props) => {
	const {
		children,
		className,
		theme = 'transparent-green',
		type = 'button',
		isLoading,
		bold = false,
		rounded = false,
		centered = false,
		size,
		...rest
	} = props
	return (
		<button
			type={type}
			{...rest}
			className={clsx(
				'button',
				`button_theme_${theme}`,
				className,
				isLoading && 'button__loading',
				bold && 'button__bold',
				rounded && 'button__rounded',
				centered && 'button__centered',
				size && `button__${size}`
			)}
		>
			{isLoading ? <span className="button__loader"></span> : children}
		</button>
	)
}
