import clsx from 'clsx'
import { ButtonHTMLAttributes, FC, MouseEventHandler } from 'react'
import { IconType } from 'react-icons'

import './toggleButton.scss'

interface IToggleButton extends ButtonHTMLAttributes<HTMLButtonElement> {
	readonly children: string
	readonly active: boolean
	readonly Icon?: IconType
	readonly CloseIcon?: IconType
	readonly handleCloseIcon?: MouseEventHandler<HTMLDivElement> | undefined
}

export const ToggleButton: FC<IToggleButton> = (props) => {
	const {
		children,
		active,
		className,
		onClick,
		Icon,
		CloseIcon,
		handleCloseIcon,
		...rest
	} = props
	return (
		<button
			onClick={onClick}
			className={clsx('toggle-button', active && 'active', className)}
			{...rest}
		>
			{Icon && <Icon className="toggle-button__icon" />}
			{children}
			{CloseIcon && (
				<div
					onClick={handleCloseIcon}
					className={clsx('toggle-button__icon', 'toggle-button__icon--close')}
				>
					<CloseIcon />
				</div>
			)}
		</button>
	)
}
