import { FC } from 'react'
import { Link, useOutletContext } from 'react-router-dom'

import { ProfileGameData } from 'widgets/profileAbout/gameData'
import { ProfileWorkouts } from 'widgets/profileAbout/userDescription'
import { ProfileMainInfo } from 'widgets/profileAbout/userInfo'

import { IUserData } from 'shared/api/account'
import { ArrowBlackBg, EditBlackBg } from 'shared/icons'
import { useResize } from 'shared/lib/useResize/useResize'

import './profileAbout.scss'

interface IProfileAbout {}

export const ProfileAbout: FC<IProfileAbout> = () => {
	const { userProfile, handleOpenNavigation } = useOutletContext() as {
		userProfile: IUserData
		handleOpenNavigation: () => void
	}
	const { isXLargeScreen } = useResize()
	return (
		<div className="profile-about">
			<div className="profile-about__header profile__sticky">
				<div className="profile__header-title">
					{!isXLargeScreen && (
						<button onClick={handleOpenNavigation}>
							<ArrowBlackBg />
						</button>
					)}
					<h1>Мой профиль</h1>
				</div>
				<Link to="/profile/edit">
					<EditBlackBg />
				</Link>
			</div>
			<section className="profile-about__content">
				<ProfileMainInfo data={userProfile} />
				<div className="profile-about__content__secondary">
					<ProfileGameData data={userProfile} />
					<ProfileWorkouts data={userProfile} />
				</div>
			</section>
		</div>
	)
}
