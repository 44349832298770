import clsx from 'clsx'
import { FC, useEffect, useState } from 'react'
import { Link, Outlet, useLocation, useNavigate } from 'react-router-dom'

import { PROFILE_LINKS } from 'shared/constants'
import { useResize } from 'shared/lib/useResize/useResize'
import { useSessionStore } from 'shared/model'
import { PuffSpinner } from 'shared/ui/puffSpinner'

import { useUserProfileQuery } from '../api/userProfile'
import './profile.scss'

interface IProfile {}

export const Profile: FC<IProfile> = () => {
	const { pathname } = useLocation()
	const session = useSessionStore((state) => state.session)
	const {
		data: userProfile,
		isSuccess,
		isPending,
	} = useUserProfileQuery(session?.id)
	useEffect(() => {
		window.scrollTo(0, 0)
	}, [pathname])

	const navigate = useNavigate()
	useEffect(() => {
		if (!session) {
			navigate('/')
		}
	}, [session, navigate])
	const [isNavigationOpen, setIsNavigationOpen] = useState(false)
	const { isXLargeScreen } = useResize()
	const handleOpenNavigation = () => {
		setIsNavigationOpen(true)
		document.body.style.overflow = 'hidden'
	}
	const handleCloseNavigation = () => {
		setIsNavigationOpen(false)
		document.body.style.overflow = ''
	}
	return (
		<div className="profile">
			{!isXLargeScreen && (
				<div
					onClick={handleCloseNavigation}
					className={clsx('profile__overlay', isNavigationOpen && 'active')}
				/>
			)}
			<aside className={clsx('profile__aside', isNavigationOpen && 'active')}>
				<ul className="profile__aside__links">
					{PROFILE_LINKS.map(({ title, path, Icon }) => (
						<li key={path}>
							<Link
								onClick={handleCloseNavigation}
								to={path}
								className={clsx(
									'profile__aside__links__item',
									pathname === path && 'active'
								)}
							>
								<Icon />
								<span>{title}</span>
							</Link>
						</li>
					))}
				</ul>
			</aside>
			{isSuccess && !isPending && (
				<div className="profile__content">
					<Outlet context={{ userProfile, handleOpenNavigation }} />
				</div>
			)}
			{isPending && <PuffSpinner />}
		</div>
	)
}
