import dayjs from 'dayjs'
import { useSearchParams } from 'react-router-dom'

import { useResize } from '../../../../shared/lib/useResize/useResize'
import { useScheduleStore } from '../../../../shared/model/schedule-store'
import CurrentRangeSwitcher from '../rangeSwitcher/RangeSwitcher'
import { ScheduleFilter } from '../scheduleFilter/scheduleFilter'
import { ScheduleFilterMob } from '../scheduleFilter/scheduleFilterMob'
import SwitchView from '../switchVIew/SwitchView'
import './scheduleHeader.scss'

export default function ScheduleHeader() {
	const { requestParams, setRequestParams, currentStartRange } =
		useScheduleStore(
			({ requestParams, setRequestParams, currentStartRange }) => ({
				requestParams,
				setRequestParams,
				currentStartRange,
			})
		)
	const params = new URLSearchParams(requestParams)
	const [searchParams, setSearchParams] = useSearchParams()

	const isFilterActive =
		params.has('level__in') ||
		params.has('trainer__in') ||
		params.has('gym__in') ||
		searchParams.has('id')

	const clearFilter = () => {
		params.delete('level__in')
		params.delete('trainer__in')
		params.delete('gym__in')
		setRequestParams(params.toString())
		setSearchParams('')
	}

	const { isMobileScreen, isMediumScreen } = useResize()
	const selectedDay = dayjs(currentStartRange)
	const rangeString = selectedDay.format('MMMM YYYY')
	return (
		<article className={'schedule-header'}>
			<div className="schedule-header__bar">
				<div className={'schedule-header__bar__manage'}>
					<span className="schedule-header__bar__manage__date">
						{rangeString}
					</span>
					{!isMobileScreen && <CurrentRangeSwitcher />}
				</div>
				{isMediumScreen ? (
					<ScheduleFilterMob
						clearFilter={clearFilter}
						isFilterActive={isFilterActive}
						params={params}
						setRequestParams={setRequestParams}
					/>
				) : (
					<ScheduleFilter
						clearFilter={clearFilter}
						isFilterActive={isFilterActive}
						params={params}
						setRequestParams={setRequestParams}
					/>
				)}
				<SwitchView />
			</div>
			{isMobileScreen && <CurrentRangeSwitcher />}
		</article>
	)
}
