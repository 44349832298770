import { FC } from 'react'
import { useOutletContext } from 'react-router-dom'

import { ChangePasswordForm } from 'widgets/changePasswordForm'

import { ArrowBlackBg } from 'shared/icons'
import { useResize } from 'shared/lib/useResize/useResize'

import './changePassword.scss'

interface IChangePassword {}

export const ChangePassword: FC<IChangePassword> = () => {
	const { isXLargeScreen } = useResize()
	const { handleOpenNavigation } = useOutletContext() as {
		handleOpenNavigation: () => void
	}
	return (
		<div className="change-password">
			<div className="profile__header-title profile__sticky">
				{!isXLargeScreen && (
					<button onClick={handleOpenNavigation}>
						<ArrowBlackBg />
					</button>
				)}
				<h1>Изменение пароля</h1>
			</div>
			<ChangePasswordForm />
		</div>
	)
}
