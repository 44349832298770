// Components
// Context
import { useQuery } from '@tanstack/react-query'
import clsx from 'clsx'
import { useEffect, useState } from 'react'

import { updateToken } from 'shared/api/account'
import { IGroupProduct } from 'shared/api/product/types'
import { useResize } from 'shared/lib/useResize/useResize'
import { useSessionStore } from 'shared/model'
import { ToggleButton } from 'shared/ui/toggleButton'

// Files
import './PaymentPage.scss'
import { getAllProductsOptions } from './api/groupProducts'
import { ProductCard } from './productCard'

const PaymentPage = () => {
	useEffect(() => {
		document.body.scrollIntoView({ behavior: 'smooth' })
	}, [])

	const session = useSessionStore((state) => state.session)
	const [userId, setUserId] = useState<number | 'guest'>('guest')
	const [active, setActive] = useState<IGroupProduct | null>(null)

	useEffect(() => {
		setUserId(session ? session.id : 'guest')
	}, [session])

	const { data: products, isSuccess } = useQuery(getAllProductsOptions(userId))

	useEffect(() => {
		if (isSuccess) {
			const groupProduct = products.find(({ slug }) => slug === 'group')!
			setActive(groupProduct)
		}
	}, [isSuccess, products])

	const handleClick = (id: number) => {
		if (id === active?.id) return
		setActive(products?.find((product) => product.id === id)!)
	}
	const { isMobileScreen } = useResize()
	return (
		<div className="products">
			<article className="products__header">
				<h1 className="products__header__title">Цены</h1>
				<div className="products__header__buttons">
					{isSuccess &&
						products.map(({ id, title }) => (
							<ToggleButton
								className={clsx(
									'fade-in',
									isMobileScreen && 'products__header__buttons__toggle-mobile'
								)}
								key={id}
								onClick={() => handleClick(id)}
								active={active?.id === id}
							>
								{title}
							</ToggleButton>
						))}
				</div>
			</article>
			<section>
				{active && (
					<ul className="products__options">
						{active.products.map((product) => (
							<li key={product.id}>
								<ProductCard product={product} group={active.title} />
							</li>
						))}
					</ul>
				)}
			</section>
		</div>
	)
}

export default PaymentPage
