import dayjs from 'dayjs'
import 'dayjs/locale/ru'
import { create } from 'zustand'
import { createJSONStorage, persist } from 'zustand/middleware'

import { getStartEndRange } from 'shared/lib/utils'

dayjs.locale('ru')

interface IScheduleStore {
	scheduleView: 'week' | 'month'
	currentStartRange: dayjs.Dayjs
	requestParams: string
	selectedDay: dayjs.Dayjs
	setSelectedDay: (date: dayjs.Dayjs) => void
	setRequestParams: (requestParams: string) => void
	setCurrentStartRange: (date: dayjs.Dayjs) => void
	setScheduleView: (scheduleView: 'week' | 'month') => void
}

export const useScheduleStore = create<IScheduleStore>()(
	persist(
		(set, get) => {
			return {
				scheduleView: 'week',
				currentStartRange: dayjs(),
				requestParams: getStartEndRange().strParam,
				selectedDay: dayjs(),
				setSelectedDay: (date) => set({ selectedDay: date }),
				setRequestParams: (requestParams) => {
					set({ requestParams })
				},
				setCurrentStartRange: (date) => {
					const params = new URLSearchParams(get().requestParams)
					const { start_date, end_date } = getStartEndRange(
						date,
						get().scheduleView
					)
					params.set('start_date', start_date)
					params.set('end_date', end_date)
					const requestParams = params.toString()
					set({ requestParams: requestParams })
					set({ currentStartRange: date })
				},
				setScheduleView: (scheduleView) => {
					const params = new URLSearchParams(get().requestParams)
					const { start_date, end_date } = getStartEndRange(
						get().currentStartRange,
						scheduleView
					)
					params.set('start_date', start_date)
					params.set('end_date', end_date)
					const requestParams = params.toString()
					set({ requestParams })
					set({ scheduleView })
				},
			}
		},
		{ name: 'Schedule', storage: createJSONStorage(() => sessionStorage) }
	)
)
