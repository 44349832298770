import clsx from 'clsx'
import { FC } from 'react'

import { TrainerIcon } from 'shared/icons'

import trainer from './trainer.png'
import './trainer.scss'

interface ITrainer {
	readonly first_name: string
	readonly last_name: string
	readonly className?: string
	readonly shouldBreak?: boolean
}

export const Trainer: FC<ITrainer> = ({
	first_name,
	last_name,
	className,
	shouldBreak,
}) => {
	return (
		<div className={clsx('class-trainer', className)}>
			<TrainerIcon />
			<div className={clsx('trainer-name')}>
				<span>{last_name}</span> {shouldBreak && <br />}
				<span>{first_name}</span>
			</div>
		</div>
	)
}
