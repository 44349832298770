import { FC } from 'react'

import { ReactComponent as EditSvg } from 'shared/assets/icons/editBlackBg.svg'

interface IEditBlackBg {
	readonly className?: string
}

export const EditBlackBg: FC<IEditBlackBg> = ({ className }) => {
	return <EditSvg className={className} />
}
