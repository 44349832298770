import { ChangeEvent, FC, useState } from 'react'
import {
	Control,
	Controller,
	FieldErrors,
	UseFormSetValue,
} from 'react-hook-form'

import { ProfileCard } from 'entities/profileCard'

import { ISettingFields } from 'shared/api/account'
import { GreenArrow } from 'shared/icons'
import { toastError } from 'shared/lib/handleError'

import './profileSecondaryInfo.scss'

interface IProfileSecondaryInfo {
	readonly avatar: string | undefined
	readonly control: Control<ISettingFields>
	readonly setValue: UseFormSetValue<ISettingFields>
	readonly errors: FieldErrors<ISettingFields>
}

export const ProfileSecondaryInfo: FC<IProfileSecondaryInfo> = ({
	avatar,
	control,
	setValue,
	errors,
}) => {
	const [userAvatar, setUserAvatar] = useState(avatar)
	const uploadFile = async (e: ChangeEvent<HTMLInputElement>) => {
		if (e.target.files) {
			const file = e.target.files[0]

			const loadFile = async () =>
				await new Promise((resolve, reject) => {
					const reader = Object.assign(new FileReader(), {
						onload: () => resolve(reader.result),
						onerror: () => reject(reader.error),
					})
					reader.readAsDataURL(file)
				})
			try {
				const result = (await loadFile()) as string
				setValue('avatar', result)
				setUserAvatar(result)
			} catch (error) {
				toastError(error)
			}
		}
	}
	return (
		<article className="secondary-info">
			<ProfileCard className="secondary-info__card">
				<div className="secondary-info__card__avatar-wrapper">
					<img
						className="secondary-info__avatar"
						src={userAvatar}
						alt="Аватар игрока"
					/>
					<p className="secondary-info__avatar-label">
						{errors.avatar ? (
							<span className="red-highlight">{errors.avatar.message}</span>
						) : (
							<>
								Фото <span className="green-highlight">*</span>
							</>
						)}
					</p>
					<Controller
						name="avatar"
						control={control}
						render={({ field }) => (
							<div className="secondary-info__control">
								<label
									htmlFor="avatar"
									className="secondary-info__control__change-button"
								>
									<span className="secondary-info__control__change-button__label">
										Загрузить
									</span>
									<GreenArrow className="secondary-info__control__change-button__icon" />
								</label>
								<input
									className="secondary-info__control__input-file"
									type="file"
									{...field}
									onChange={uploadFile}
									id="avatar"
									value={''}
									accept="image/*"
								/>
							</div>
						)}
					/>
				</div>
				<Controller
					name="about"
					control={control}
					render={({ field }) => (
						<div className="secondary-info__control">
							<label>О себе</label>
							<textarea
								placeholder="Расскажите о себе"
								className="secondary-info__control__about"
								{...field}
							/>
						</div>
					)}
				/>
			</ProfileCard>
		</article>
	)
}
