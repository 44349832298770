import { useQuery } from '@tanstack/react-query'
import clsx from 'clsx'
import { FC, useEffect, useState } from 'react'
import { MdOutlineClose } from 'react-icons/md'

import { useResize } from 'shared/lib/useResize'
import { Dropdown } from 'shared/ui/dropdown'

import { classLevelOptions, gymsOptions, trainersOptions } from './api/filter'
import './scheduleFilter.scss'

interface IScheduleFilter {
	readonly transition?: 'fade' | 'slide'
	readonly isFilterMobile?: boolean
	readonly clearFilter: () => void
	readonly isFilterActive: boolean
	readonly params: URLSearchParams
	readonly setRequestParams: (requestParams: string) => void
}

export const ScheduleFilter: FC<IScheduleFilter> = ({
	transition = 'fade',
	isFilterMobile,
	clearFilter,
	isFilterActive,
	params,
	setRequestParams,
}) => {
	const defaultDropdownState = {
		gym: false,
		trainer: false,
		level: false,
	}
	const [state, setState] = useState(defaultDropdownState)
	const { data: levels } = useQuery(classLevelOptions)
	const { data: trainers } = useQuery(trainersOptions)
	const { data: gyms } = useQuery(gymsOptions)

	const { isMediumScreen } = useResize()

	const handleClose = () => {
		setState(defaultDropdownState)
	}

	const handleClickOutside = (e: any) => {
		const dropdownBoxClicked = e.target?.closest('#filter-dropdown')
		if (!dropdownBoxClicked) {
			handleClose()
		}
	}
	useEffect(() => {
		if (!isMediumScreen) {
			window.addEventListener('click', (e) => handleClickOutside(e))
		}
		return () =>
			window.removeEventListener('click', (e) => handleClickOutside(e))
	}, [isMediumScreen])

	const handleFilter = (
		filter: 'level__in' | 'trainer__in' | 'gym__in',
		id: number
	) => {
		const stringId = id.toString()
		if (params.has(filter)) {
			const currentFilter = params.get(filter)!.split(',')
			if (currentFilter?.includes(stringId)) {
				const newFilter = currentFilter.filter((item) => item !== stringId)
				newFilter.length === 0
					? params.delete(filter)
					: params.set(filter, newFilter.join(','))
			} else {
				currentFilter?.push(stringId)
				params.set(filter, currentFilter?.join(','))
			}
		} else {
			params.append(filter, id.toString())
		}
		setRequestParams(params.toString())
	}
	const toggleDropdown = (filter: 'gym' | 'trainer' | 'level') => {
		const openState = state[filter]
		isFilterMobile
			? setState((prev) => ({ ...prev, [filter]: !prev[filter] }))
			: setState({ ...defaultDropdownState, [filter]: !openState })
	}
	return (
		<div className="schedule-filter">
			<Dropdown
				isOpen={state.trainer}
				label={'Тренер'}
				active={params.has('trainer__in')}
				handleClick={() => toggleDropdown('trainer')}
				transition={transition}
			>
				{trainers?.map((trainer) => (
					<li
						className={clsx(
							'option',
							params.get('trainer__in')?.includes(trainer.id.toString()) &&
								'active'
						)}
						key={trainer.id}
						onClick={(e) => {
							e.stopPropagation()
							handleFilter('trainer__in', trainer.id)
						}}
					>
						{trainer.last_name} {trainer.first_name}
					</li>
				))}
			</Dropdown>
			<Dropdown
				isOpen={state.gym}
				label={'Зал'}
				active={params.has('gym__in')}
				handleClick={() => toggleDropdown('gym')}
				transition={transition}
			>
				{gyms?.map((gym) => (
					<li
						key={gym.id}
						className={clsx(
							'option',
							params.get('gym__in')?.includes(gym.id.toString()) && 'active'
						)}
						onClick={(e) => {
							e.stopPropagation()
							handleFilter('gym__in', gym.id)
						}}
					>
						{gym.name}
					</li>
				))}
			</Dropdown>
			<Dropdown
				isOpen={state.level}
				label={'Уровень'}
				active={params.has('level__in')}
				handleClick={() => toggleDropdown('level')}
				transition={transition}
			>
				{levels?.map((level) => (
					<li
						key={level.id}
						className={clsx(
							'option',
							params.get('level__in')?.includes(level.id.toString()) && 'active'
						)}
						onClick={(e) => {
							e.stopPropagation()
							handleFilter('level__in', level.id)
						}}
					>
						{level.title}
					</li>
				))}
			</Dropdown>
			{
				<button
					className={clsx('clear__btn', isFilterActive && 'show-clear')}
					onClick={clearFilter}
				>
					<MdOutlineClose />
					<span>Очистить</span>
				</button>
			}
		</div>
	)
}
