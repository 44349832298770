import { FC } from 'react'

import { ReactComponent as StarSvg } from 'shared/assets/icons/star.svg'

interface IStarIcon {
	readonly className?: string
}

export const StarIcon: FC<IStarIcon> = ({ className }) => {
	return <StarSvg className={className} />
}
