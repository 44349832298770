import dayjs from 'dayjs'
import updateLocale from 'dayjs/plugin/updateLocale'
import { useEffect, useState } from 'react'

import { ScheduleWeek } from 'widgets/scheduleWeek'

import { getSession } from 'shared/lib/auth'
import { useResize } from 'shared/lib/useResize'
import { useRange } from 'shared/lib/utils'
import { useScheduleStore } from 'shared/model'
import { IClass } from 'shared/types'
import { PuffSpinner } from 'shared/ui/puffSpinner'

import './SchedulePage.scss'
import Month from './zScheduleCal/Month/Month'
import { useClassesQuery } from './zScheduleCal/api/classes'
import ScheduleHeader from './zScheduleCal/scheduleHeader/ScheduleHeader'

dayjs.extend(updateLocale)

dayjs.updateLocale('ru', {
	monthsShort: [
		'янв',
		'фев',
		'мар',
		'апр',
		'мая',
		'июн',
		'июл',
		'авг',
		'сен',
		'окт',
		'ноя',
		'дек',
	],
})

const SchedulePage = () => {
	const [userId, setUserId] = useState<number | null>(null)
	const user = getSession()?.id
	useEffect(() => {
		setUserId(user ? user : null)
	}, [user])
	const { scheduleView, requestParams } = useScheduleStore(
		({ requestParams, scheduleView }) => ({
			requestParams,
			scheduleView,
		})
	)
	const { isMediumScreen } = useResize()
	const {
		data: rangeClasses,
		isLoading,
		isSuccess,
	} = useClassesQuery(requestParams, userId || 'guest')

	const { currentStartRange } = useRange()
	useEffect(() => {
		const element = document.getElementById('week-mode-today')
		if (isMediumScreen && scheduleView === 'week') {
			element
				? element.scrollIntoView({ block: 'center', behavior: 'smooth' })
				: window.scroll({ top: 100, left: 0, behavior: 'smooth' })
		} else {
			window.scroll({
				behavior: 'smooth',
				top: 100,
				left: 0,
			})
		}
	}, [scheduleView, isMediumScreen, currentStartRange])
	const renderSchedule = (classes: IClass[]) => {
		const components = {
			week: (
				<ScheduleWeek
					rangeClasses={classes}
					currentStartRange={currentStartRange}
				/>
			),
			month: (
				<Month rangeClasses={classes} currentStartRange={currentStartRange} />
			),
		}
		return components[scheduleView]
	}
	return (
		<section className="schedule">
			{/*** Page heading ***/}
			<article className="schedule__header">
				<h1 className="title">{'Расписание'}</h1>
				{/* {isLargeScreen && (
          <div className="schedule-ad-wrapper">
            <div className="schedule-ad-image">
              <img src={pageInfo?.image} alt="Объявление" />
            </div>
            <ul className="levels">
              {USER_LEVELS.map(({ colors, title }) => {
                return (
                  <li className="level" key={title}>
                    <Level colors={colors} width={6} />
                    <span>{`${title} уровень`}</span>
                  </li>
                );
              })}
            </ul>
          </div>
        )} */}
			</article>

			<article className="schedule__classes" id="schedule">
				<ScheduleHeader />
				<div className="schedule__classes__content">
					{isLoading && <PuffSpinner />}{' '}
					{isSuccess && !isLoading && renderSchedule(rangeClasses)}
				</div>
			</article>

			{/*** Class reviews popup ***/}
			{/* <ScheduleClassReviews /> */}
		</section>
	)
}

export default SchedulePage
