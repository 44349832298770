import clsx from 'clsx'
import { FC } from 'react'

import { GroupIcon } from 'shared/icons'
import { IClassById } from 'shared/types'
import { Button } from 'shared/ui/button'
import { GymAddress } from 'shared/ui/gymAddress'
import { IconTitle } from 'shared/ui/iconTitle'
import { LevelInfo } from 'shared/ui/level'
import { Metro } from 'shared/ui/metro'
import { Trainer } from 'shared/ui/trainer'

import './workoutInfo.scss'

interface IWorkoutInfo {
	readonly workout: IClassById
	readonly setOverlayOpen: (value: React.SetStateAction<boolean>) => void
}

export const WorkoutInfo: FC<IWorkoutInfo> = ({ workout, setOverlayOpen }) => {
	const {
		type,
		level,
		gym,
		trainer,
		is_changed_gym,
		is_changed_level,
		is_changed_trainer,
	} = workout
	return (
		<article className="workout-info">
			<div className="workout-info__main">
				<div className="workout-info__main__description">
					<IconTitle Icon={GroupIcon} title={type} />
					<LevelInfo
						levelTitle={level.title}
						levelColor={level.color}
						is_changed_level={is_changed_level}
					/>
					<Metro
						className={clsx(is_changed_gym && 'red-highlight')}
						title={gym.title}
					/>
					<GymAddress
						className={clsx(is_changed_gym && 'red-highlight')}
						title={gym.address}
					/>
					<Trainer
						className={clsx(is_changed_trainer && 'red-highlight')}
						first_name={trainer.first_name}
						last_name={trainer.last_name}
					/>
				</div>
			</div>
			<div className="workout-info__additional">
				<h3>Дополнительная информация</h3>
				<p>
					Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
					eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad
					minim veniam, quis nostrud exercitation ullamco laboris nisi ut
					aliquip ex ea commodo consequat.
				</p>
			</div>
			<div className="workout-info__buttons">
				<Button bold theme="black">
					Как добраться
				</Button>
				<Button onClick={() => setOverlayOpen(true)} bold theme="black">
					Посмотреть уровень
				</Button>
			</div>
		</article>
	)
}
