import { create } from 'zustand'

import { IUser } from 'shared/api/account'
import { getSession } from 'shared/lib/auth'
import { getRandomAvatar } from 'shared/lib/utils'

interface ISessionStore {
	session: IUser | null
	setSession: (session: IUser | null) => void
	isPopupAccountOpen: boolean
	setIsPopupAccountOpen: (isPopupAccountOpen: boolean) => void
	defaultAvatar: string
}

export const useSessionStore = create<ISessionStore>()((set) => ({
	session: getSession(),
	setSession: (session) => set({ session }),
	isPopupAccountOpen: false,
	setIsPopupAccountOpen: (isPopupAccountOpen) => set({ isPopupAccountOpen }),
	defaultAvatar: getRandomAvatar(getSession()?.sex ?? null),
}))
