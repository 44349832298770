import { FC } from 'react'
import { useNavigate, useOutletContext } from 'react-router-dom'

import { ProfileSubscribe } from 'widgets/profileSubscribe'
import { Slider } from 'widgets/slider'

import { IUserData } from 'shared/api/account'
import { ArrowBlackBg } from 'shared/icons'
import { useResize } from 'shared/lib/useResize/useResize'
import { Button } from 'shared/ui/button'

import './profileSubscribes.scss'

interface IProfileSubscribes {}

export const ProfileSubscribes: FC<IProfileSubscribes> = () => {
	const { userProfile, handleOpenNavigation } = useOutletContext() as {
		userProfile: IUserData
		handleOpenNavigation: () => void
	}
	const navigate = useNavigate()
	const { isXLargeScreen, isMobileScreen, width } = useResize()
	const renderSubscribes = () =>
		userProfile.balance.map((subscribe, idx) => (
			<ProfileSubscribe
				isDefault={idx === 0}
				key={subscribe.id}
				subscribe={subscribe}
			/>
		))
	return (
		<div className="profile-subscribes">
			<div className="profile-subscribes__header profile__sticky">
				<div className="profile__header-title">
					{!isXLargeScreen && (
						<button onClick={handleOpenNavigation}>
							<ArrowBlackBg />
						</button>
					)}
					<h1>Абонементы</h1>
				</div>
				<Button
					className="profile-subscribes__header__button"
					onClick={() => navigate('/payment')}
				>
					Купить абонемент
				</Button>
			</div>
			<section className="profile-subscribes__content">
				{width > 450 ? (
					renderSubscribes()
				) : (
					<Slider arrows={false} rectangleDots>
						{renderSubscribes()}
					</Slider>
				)}
			</section>
			{isMobileScreen && (
				<div className="profile-subscribes__footer">
					<Button onClick={() => navigate('/payment')} size="large" centered>
						Купить Абонемент
					</Button>
				</div>
			)}
		</div>
	)
}
